import { TRIAL_DURATION } from '../../../../utils/constants/settings';

const es = {
  'onboarding.creteAccount.acceptAllTerms':
    'Al registrarte habrás aceptado: los <terms>Términos de servicio</terms>, la <cookies>Política de cookies</cookies>, la <privacy>Política de privacidad</privacy>, los <caPrivacy>Derechos de privacidad de CA</caPrivacy> y el <doNotSell>Do Not Sell My Information</doNotSell> de Social Piper, además automáticamente estarás registrado para recibir correos electrónicos sobre nuevos productos, mejores prácticas u ofertas especiales de Social Piper, hasta que decidas no recibirlos.',
  'onboarding.createAccount.title': 'Crea tu cuenta',
  'onboarding.AboutAudience.gender': 'Género',
  'onboarding.AboutAudience.GenderAll': 'TODOS',
  'onboarding.AboutAudience.GenderFemale': 'MUJERES',
  'onboarding.AboutAudience.GenderMale': 'HOMBRES',
  'onboarding.AboutAudience.age': 'Edad',
  'onboarding.creteAccount.heading': 'Crea tu cuenta',
  'onboarding.createAccount.selectPlan.save': 'Ahorra 2 meses',
  'onboarding.createAccount.selectPlan.title': 'Selecciona tu Plan',
  'onboarding.creteAccount.subheader':
    'Llena los siguientes datos para comenzar tu prueba gratis de 7 días',
  'onboarding.creteAccount.noRisk': 'Sin Riesgo. Sin Obligaciones.',
  'onboarding.creteAccount.lastname': 'Apellido',
  'onboarding.creteAccount.firstname': 'Nombre',
  'onboarding.creteAccount.password': 'Contraseña',
  'onboarding.creteAccount.mobile': 'Teléfono (Opcional)',
  'onboarding.creteAccount.email': 'E-mail',
  'onboarding.createAccount.TOS':
    'Acepto los <a>Términos de Servicio</a> de Social Piper.',
  'onboarding.createAccount.cookies':
    'Acepto la <a>Política de Cookies</a> de Social Piper.',
  'onboarding.createAccount.description':
    'Comienza con tus 7 días de prueba gratuita.',
  'onboarding.createAccount.policy': 'He leído el <a>Aviso de privacidad</a>.',
  'onboarding.createAccount.wantsEmails':
    'Deseo recibir correos electrónicos acerca de nuevos productos de Social Piper, mejores prácticas y ofertas especiales.',
  'onboarding.creteAccount.signUp': 'Registrarse',
  'onboarding.creteAccount.trialmessage':
    '* Prueba gratuita de 7 días. $99 USD/mes después de la prueba. No se necesita tarjeta de crédito durante el periodo de prueba.',
  'onboarding.creteAccount.trialmessageMobile':
    '<strong>¡Obtén tu prueba gratuita de 7 días!</strong> Pruébalo y comienza a hacer crecer tu negocio en redes sociales.',
  'onboarding.creteAccount.optout':
    'No quiero recibir correos electrónicos acerca del nuevo Social Piper, productos, mejores prácticas u ofertas especiales.',
  'onboarding.creteAccount.or': 'o',
  'onboarding.creteAccount.loginWithFB': 'Inicia sesión con Facebook',
  'onboarding.creteAccount.signUpWithFB': 'Regístrate vía Facebook',
  'onboarding.creteAccount.signUpWithFB.free': 'y comienza tu prueba gratuita',
  'onboarding.creteAccount.TOS':
    'Al continuar, acepto los <tos>Términos de servicio</tos>, <cguidelines>Principios de Comunidad</cguidelines> y <privacypolicy>Aviso de Privacidad</privacypolicy> de Social Piper.',
  'onboarding.creteAccount.pricinginfo': 'Sin riesgo. Sin oblicaciones.',
  'onboarding.creteAccount.pricinginfo2':
    'Se te cobrará <strong> $99 USD /mes </strong> si continuas después del periodo de prueba.',
  'onboarding.creteAccount.pricinginfodisclaimer':
    '*Las tarifas de suscripción de Social Piper NO se cobrarán durante el periodo de prueba. Se pueden aplicar tarifas / comisiones publicitarias.',
  'onboarding.creteAccount.trialPricing': '$99 USD / mes',
  'onboarding.creteAccount.trial.title': `Comienza tu período de prueba de ${TRIAL_DURATION} días gratis*`,
  'onboarding.creteAccount.14DayTrial': '7 días de prueba gratuita*',
  'onboarding.creteAccount.reactivateBusinessTitle':
    '¡Te regresamos dinero <subtitle> para reactivar tu negocio!</subtitle>',
  'onboarding.creteAccount.limitedTimeOffer':
    'Por <green>tiempo limitado</green>, activa tu cuenta en Social Piper, y recibe un <strong>30% de descuento en la tarifa mensual </strong> ($99 USD /mes precio normal), además vamos a <strong> regresar el valor del primer mes en tu tarjeta de crédito </strong> para que puedas usarlo en crear una campaña en línea y llegar a más clientes (o cualquier otro uso que tu negocio necesite).',
  'onboarding.creteAccount.limitedTime': 'Tiempo limitado',
  'onboarding.creteAccount.30DayTrial': '30 días de prueba gratis*',
  'onboarding.creteAccount.7DayTrial': '7 días de prueba gratis*',
  'onboarding.creteAccount.reactivateBusinessSubTitle':
    '¡para reactivar tu negocio!',
  'onboarding.createAccount.BoostingPowerTitle': '¡Promociona tu contenido!',
  'onboarding.creteAccount.BoostingPower':
    '<strong>Piper</strong> te ayuda a llegar a un mayor público, tomando el control de tus gastos y con la ayuda de recomendaciones basadas en inteligencia artificial.',
  'onboarding.creteAccount.BoostingPowerParagraph':
    'Por ejemplo "Con $150 tu publicación puede alcanzar hasta 17,000 usuarios."',
  'onboarding.piperPresentation.piperTip':
    'Hola! Soy Piper, tu confiable guía de redes sociales. Estaré a tu lado con ideas y consejos, en cada paso importante.',
  'onboarding.stepper.first': 'CREA TU CUENTA',
  'onboarding.stepper.second': 'INFORMACIÓN DE TU NEGOCIO',
  'onboarding.stepper.third': 'Tu Público',
  'onboarding.stepper.fourth': 'TU PLAN',
  'onboarding.redirectModal.title':
    'Conecta tus cuentas para crear tu primer publicación',
  'onboarding.redirectModal.tip': 'Ahora, elige qué quieres hacer',
  'onboarding.redirectModal.createPost.text': 'Crea tu primera publicación',
  'onboarding.redirectModal.createPost.button': 'Crear',
  'onboarding.redirectModal.mediaLibrary.text':
    'Conoce los templates gratuitos',
  'onboarding.redirectModal.mediaLibrary.subtext':
    'y selecciona algo para publicar',
  'onboarding.redirectModal.mediaLibrary.button': 'Explora',
  'onboarding.redirectModal.social.text': 'Conecta tus redes sociales',
  'onboarding.redirectModal.social.subtext': 'y agrega a tus competidores',
  'onboarding.redirectModal.social.button': 'Conecta',
  'onboarding.redirectModal.dashboard':
    'O puedes ir directamente a tu Dashboard y explorar el resto de Social Piper',
  'onboarding.creteAccount.doyouhavecode': '¿Tienes un código de promoción?',
  'onboarding.creteAccount.validcode': 'Código Válido',
  'onboarding.creteAccount.validcode2': 'Código válido. Los días de prueba incluidos en el cupón sólo son válidos para cuentas nuevas.',
  'onboarding.creteAccount.invalidcode': 'Código Inválido',
  'onboarding.pricing.titleCICC': 'Oferta especial para miembros de CICC:',
  'onboarding.pricing.titleASEM': 'Oferta especial para miembros de ASEM:',
  'onboarding.pricing.titleAMAI': 'Oferta especial para miembros de AMAI:',
  'onboarding.pricing.titleAPREN':
    'Oferta especial para miembros de Aprendamos Marketing:',
  'onboarding.pricing.titleSP100': 'Cuenta especial:',
  'onboarding.pricing.OfferCICC':
    '<strong>Suscripción Mensual:</strong> {lineBreak}<no>$99</no> <red>USD $29/Mes</red> <small><up>1</up></small>{lineBreak}<strong>Suscripción Anual:</strong> {lineBreak}<no>$990</no> <red>USD $290</red> <small><up>2</up></small>',
  'onboarding.pricing.OfferASEM':
    '<strong>Suscripción Mensual:</strong> {lineBreak}<no>$99</no> <red>USD $29/Mes</red> <small><up>1</up></small>{lineBreak}<strong>Suscripción Anual:</strong> {lineBreak}<no>$990</no> <red>USD $290</red> <small><up>2</up></small>',
  'onboarding.pricing.OfferMonthly':
    '<strong>Suscripción Mensual:</strong> {lineBreak}',
  'onboarding.pricing.OfferYearly':
    '<strong>Suscripción Anual:</strong> {lineBreak}',
  'onboarding.pricing.descriptionOfferCICC':
    'Esta oferta está abierta a todos los miembros de la CICC, para que puedan empezar a usar y utilizar Social Piper. Para obtener este beneficio, es necesario crear una cuenta y activar tu suscripción antes de que la oferta expire.{lineBreak}',
  'onboarding.pricing.descriptionOfferASEM':
    'Esta oferta está abierta a todos los miembros de la ASEM, para que puedan empezar a usar y utilizar Social Piper. Para obtener este beneficio, es necesario crear una cuenta y activar tu suscripción antes de que la oferta expire.{lineBreak}',
  'onboarding.pricing.descriptionOfferAMAI':
    'Esta oferta está abierta a todos los miembros de la AMAI, para que puedan empezar a usar y utilizar Social Piper. Para obtener este beneficio, es necesario crear una cuenta y activar tu suscripción antes de que la oferta expire.{lineBreak}',
  'onboarding.pricing.descriptionOfferAPREN':
    'Esta oferta está abierta a todos los miembros de Aprendamos Marketing, para que puedan empezar a usar y utilizar Social Piper. Para obtener este beneficio, es necesario crear una cuenta y activar tu suscripción antes de que la oferta expire.{lineBreak}',
  'onboarding.pricing.descriptionOfferSP100':
    'Esta cuenta es para propósitos especiales; todas las funcionalidades están disponibles sin costo mensual pero no excluye la comisión sobre el gasto de creación en campañas publicitarias.{lineBreak}',
  'onboarding.pricing.termsOfferCICC':
    'Promoción valida hasta marzo de 2021, limitado a 2,000 redenciones. Esta promoción será aplicada una vez que el usuario active su cuenta.',
  'onboarding.pricing.termsOfferASEM':
    'Promoción valida hasta marzo de 2021, limitado a 20,000 redenciones. Esta promoción será aplicada una vez que el usuario active su cuenta.',
  'onboarding.pricing.termsOfferAMAI':
    'Promoción valida hasta marzo de 2021, limitado a 10,000 redenciones. Esta promoción será aplicada una vez que el usuario active su cuenta.',
  'onboarding.pricing.termsOfferAPREN':
    'Promoción limitada a 1000 redenciones. Esta promoción será aplicada una vez que el usuario active su cuenta.',
  'onboarding.createAccount.title.app': 'Crea una cuenta con el plan Pro',
  'signup.createAccount.title': 'Crea tu cuenta',
  'signup.createAccount.option.text': 'O regístrate con:',
  'onboarding.creteAccount.title':
    'Estás creando una cuenta en el <r>plan PRO, con prueba gratuita de {days} días.</r>',
  'onboarding.creteAccount.title.notrial':
    'Estás creando una cuenta en el <r>plan PRO.</r>',
  'onboarding.creteAccount.title.full':
    'Estás creando una cuenta en el <r>plan GESTION TOTAL.</r>',
  'onboarding.creteAccount.subtitle.optout':
    'No se le cobrará durante su período de prueba.',
  'onboarding.creteAccount.subtitle.trial.noCode':
    'No se requiere tarjeta de crédito en el periodo de prueba. <lText>Compara los planes.</lText>',
  'onboarding.creteAccount.subtitle.optin':
    'Sin riesgos ni obligaciones.',
  'onboarding.creteAccount.email.holder':
    'Recibirás un enlace de verificación de cuenta para este correo electrónico.',
  'onboarding.createAccount.text.required': '* Requerido',
  'onboarding.launcher.dashboard': 'Omitir e ir al Dashboard',
  'onboarding.launcher.connect.facebook': 'Conecta tu página de negocio de Facebook',
  'onboarding.launcher.connect.instagram': 'Conecta tu cuenta de negocio de Instagram. <Highlight>Muestrame como</Highlight>',
  'onboarding.launcher.connect.instagram.noFacebook': 'Para conectar IG, primero conecta Fb.',
  'onboarding.launcher.connect.twitter': 'Conecta tu cuenta de X',
  'onboarding.launcher.connect.linkedin': 'Conecta tu cuenta o página de Linkedin.',
  'onboarding.launcher.connect.tiktok': 'Conecta tu cuenta de Tiktok',
  'onboarding.launcher.connect.facebook.helpInfo': '¿No tienes una página de negocio de Facebook? <LinkInfo>Crea una ahora</LinkInfo>',
  'onboarding.launcher.connect.instagram.helpInfo': '¿No sabes si tu cuenta es de negocio? <LinkInfo>Revisa estos pasos.</LinkInfo>',
  'onboarding.launcher.modal.title1': 'Primero, autentícate con Facebook',
  'onboarding.launcher.modal.item1': '<TextHighlight>Meta</TextHighlight> requiere que tu cuenta de Instagram sea de tipo Negocio y además esté vinculada con una página de negocio en Facebook.',
  'onboarding.launcher.modal.boxHelp.item': 'Si aún no lo has hecho sólo, {lineBreak}<LinkSteps>sigue estos pasos,</LinkSteps> es muy fácil.',
  'onboarding.launcher.modal.subItem1': 'Si aún no los has vinculado, <LinkSteps>sigue estos pasos.</LinkSteps>',
  'onboarding.launcher.modal.title2': 'A continuación...',
  'onboarding.launcher.modal.item2': 'Iniciarás sesión en el perfil de Facebook que tiene permiso de administrador para la página de Facebook conectada con tu perfil de negocio de Instagram.',
  'onboarding.launcher.modal.subItem2': 'Si no sabes si tu perfil de Instagram es un perfil de negocio <LinkGuide>sigue esta guía</LinkGuide> o bien <LinkSteps>conviértela siguiendo estos pasos.</LinkSteps>',
  'onboarding.launcher.modal.button': 'Conectar Instagram a través {lineBreak}de Facebook',
  'signup.createAccount.app.helpText1': '<TextBold>Estás creando una cuenta en el</TextBold> <r>plan PRO, con {days} días de prueba gratuita.</r> <LinkText>Ve las características del plan aquí.</LinkText> No tendrás cargos durante tu periodo de prueba.',
  'signup.createAccount.haveAccount.text': 'Tengo una cuenta',
  'signup.createAccount.text.required': '* Obligatorio',
  'onboarding.launcher.meeting.card.title': '“¿No quieres conectar tus cuentas aún?”',
  'onboarding.launcher.meeting.card.body.text': '<h>Agenda una asesoria personalizada</h> para guiarte y mostrarte {linebreak}todo el potencial de Social Piper para tu negocio.',
  'onboarding.launcher.meeting.card.button': 'Agendar',
  'onBoarding.launcher.trustMark.description': 'Información {lineBreak}segura',
  'onBoarding.launcher.trustMark.text': 'Somos una aplicación aprobada por:',
};

export default es;
