/* eslint-disable import/no-absolute-path */
/* eslint-disable global-require */
const path = require('path');

export const AcademyIcon = require('./academy.svg');
export const AddIcon = require('./add.svg');
export const AddIconWhite = require('./add-white.svg');
export const AddIconGreen = require('./add-green.svg');
export const AddIconPrimary = require('./add-white-primary.svg');
export const AdjustIcon = require('./adjust.svg');
export const AppStoreBadgeIcon = require('./app-store-badge.svg');
export const AppStoreBadgeEspIcon = require('./app-store-badge-esp.svg');
export const ArrowLeftCarouselIcon = require('./arrow-left-carousel.svg');
export const ArrowRightCarouselIcon = require('./arrow-right-carousel.svg');
export const ArrowCurveIcon = require('./arrow-curve-icon.svg');
export const ArrowDownIcon = require('./arrow-down.svg');
export const ArrowDownColorIcon = require('./arrow-down-color.svg');
export const ArrowLeftIcon = require('./arrow-left.svg');
export const ArrowRightIcon = require('./arrow-right.svg');
export const AfternoonIcon = require('./afternoon.svg');
export const ArrowLeftPrimaryIcon = require('./arrow-left-primary.svg');
export const ArrowRightPrimaryIcon = require('./arrow-right-primary.svg');
export const ArrowUpColorIcon = require('./arrow-up-color.svg');
export const NoChangeColorIcon = require('./no-change.svg');
export const InfoWarningColorIcon = require('./info-warning.svg');
export const ArrowsHorizontalIcon = require('./arrows-horizontal.svg');
export const ArrowsIcon = require('./arrows.svg');
export const CalendarIcon = require('./calendar.svg');
export const CalendarPrimaryIcon = require('./calendar-primary.svg');
export const CalendarCircleIcon = require('./calendar-circle.svg');
export const CaretDownIcon = require('./caret-down.svg');
export const CommentsIcon = require('./comments.svg');
export const CommentsWhiteIcon = require('./comments-white.svg');
export const CameraIcon = require('./camera.svg');
export const ChartPieIcon = require('./chart-pie.svg');
export const CheckBlueIcon = require('./check-blue.svg');
export const ChevronDownIcon = require('./chevron-down.svg');
export const ChevronDownWhiteIcon = require('./chevron-down-white.svg');
export const ChevronDownWhiteNewIcon = require('./white-chevron-down-new.svg');
export const ChevronDownGrayIcon = require('./chevron-down-gray.svg');
export const ChevronLeftIcon = require('./chevron-left.svg');
export const ChevronRightIcon = require('./chevron-right.svg');
export const ChevronLeftWhiteIcon = require('./chevron-left-white.svg');
export const ChevronRightWhiteIcon = require('./chevron-right-white.svg');
export const ChevronLeftBlueIcon = require('./chevron-left-blue.svg');
export const ChevronRightBlueIcon = require('./chevron-right-blue.svg');
export const ChevronUpIcon = require('./chevron-up.svg');
export const ClarityCircleArrowLineIcon = require('./clarity_circle-arrow-line.svg');
export const CloseOutlineIcon = require('./close-outline.svg');
export const CommentIcon = require('./comment.svg');
export const DashboardIcon = require('./dashboard.svg');
export const DeleteXIcon = require('./delete-x.svg');
export const DeleteXPrimaryIcon = require('./delete-x-primary.svg');
export const DeleteXWhiteIcon = require('./delete-x-white.svg');
export const DoggoIcon = require('./doggo.svg');
export const DoggoRoundedWhiteIcon = require('./doggo-rounded-white.svg');
export const DollarIcon = require('./dollar.svg');
export const DownloadIcon = require('./download.svg');
export const DownloadPDFIcon = require('./download-pdf.svg');
export const EditIcon = require('./edit.svg');
export const EveningIcon = require('./evening.svg');
export const EditPrimaryIcon = require('./edit-primary.svg');
export const EditWhiteIcon = require('./edit-white.svg');
export const EmailIcon = require('./email.svg');
export const EmojiIcon = require('./emoji.svg');
export const EventScheduleIcon = require('./event-schedule.svg');
export const FaceAddIcon = require('./face-add.svg');
export const FacebookIcon = require('./facebook.svg');
export const FacebookColorIcon = require('./facebook-color.svg');
export const FacebookWhiteIcon = require('./facebook-white.svg');
export const AppleColorIcon = require('./apple-color.svg');
export const FacebookDisabledIcon = require('./facebook-disabled.svg');
export const FacebookCircleBorderIcon = require('./facebook-circle-border.svg');
export const FavoriteIcon = require('./favorite.svg');
export const FavoriteIconScheduler = require('./favorite-scheduler.svg');
export const FavoriteWhiteIconScheduler = require('./favorite-scheduler-white.svg');
export const FlashIcon = require('./flash.svg');
export const FlashYellowIcon = require('./flash-yellow.svg');
export const GalleryColorIcon = require('./gallery-color-icon.svg');
export const GalleryWhiteIcon = require('./gallery-white-icon.svg');
export const GIFIcon = require('./gif.svg');
export const GIFNoShadowIcon = require('./gif-no-shadow.svg');
export const GooglePlayBadgeIcon = require('./google-play-badge.png');
export const GooglePlayBadgeEspIcon = require('./google-play-badge-esp.png');
export const GoToIcon = require('./go-to.svg');
export const HelpBlueIcon = require('./help-blue.svg');
export const HighlightLeft = require('./highlight-left.svg');
export const HighlightLeftMobile = require('./highlight-left-mobile.svg');
export const HighlightRight = require('./highlight-right.svg');
export const HighlightRightMobile = require('./highlight-right-mobile.svg');
export const HighlightSuccessIcon = require('./highlight-success.svg');
export const ImageColorIcon = require('./image-color-icon.svg');
export const ImageWhiteIcon = require('./image-white-icon.svg');
export const ImagePlaceholderIcon = require('./image-placeholder.svg');
export const ImpactIcon = require('./impact.svg');
export const InProgressIcon = require('./in-progress.svg');
export const InfoIcon = require('./info.svg');
export const InstagramIcon = require('./instagram.svg');
export const InstagramCircleBorderIcon = require('./instagram-circle-border.svg');
export const InstagramCircleIcon = require('./instagram-circle.svg');
export const InstagramCircleColorIcon = require('./instagram-circle-color.svg');
export const InstagramColorIcon = require('./instagram-color.svg');
export const InstagramDisabledIcon = require('./instagram-disabled.svg');
export const InstagramLetterIcon = require('./instagram-letter-logo.svg');
export const InstagramStoriesIcon = require('./instagram-stories.svg');
export const InstagramVerificationIcon = require('./instagram-verification-badge.svg');
export const InstagramWhiteIcon = require('./instagram-white.svg');
export const LikeIcon = require('./like.svg');
export const LikeColorIcon = require('./like-color.svg');
export const LightLikeIcon = require('./light-like.svg');
export const LightDialogIcon = require('./light-dialog.svg');
export const LinkedinIcon = require('./linkedin.svg');
export const LinkedinColorIcon = require('./linkedin-color.svg');
export const LinkedinWhiteIcon = require('./linkedin-white.svg');
export const LinkedinCircleIcon = require('./linkedin-circle.svg');
export const LinkedinCircleColorIcon = require('./linkedin-circle-color.svg');
export const LinkedinCircleBorderIcon = require('./linkedin-circle-border.svg');
export const LinkedinDisabledIcon = require('./linkedin-disabled.svg');
export const FacebokIconDisabledBoost = require('./facebook-disabled-boosting.svg');
export const ListIcon = require('./list.svg');
export const LoaderCircleIcon = require('./loader-circle-icon.svg');
export const LocationIcon = require('./location.svg');
export const MenuIcon = require('./menu.svg');
export const MediaLibraryIcon = require('./media-library.svg');
export const MorningIcon = require('./morning.svg');
export const MoreHorizontalIcon = require('./more-horizontal.svg');
export const MoreVerticalIcon = require('./more-vertical.svg');
export const MoreVerticalWhiteIcon = require('./more-vertical-white.svg');
export const NightIcon = require('./night.svg');
export const PauseIcon = require('./pause.svg');
export const PhoneIcon = require('./phone.svg');
export const PlayIcon = require('./play.svg');
export const PlayCircle = require('./play-circle.svg');
export const PlayCircleNoShadow = require('./play-circle-no-shadow.svg');
export const PlayCircleColorIcon = require('./play-circle-color-icon.svg');
export const PlayCircleWhiteIcon = require('./play-circle-white-icon.svg');
export const PrintIcon = require('./print-icon.svg');
export const ReplyIcon = require('./reply.svg');
export const RocketIcon = require('./rocket.svg');
export const SearchIcon = require('./search.svg');
export const ShareIcon = require('./share.svg');
export const ShareGreenIcon = require('./share-green.svg');
export const SettingsIcon = require('./settings.svg');
export const LogoutIcon = require('./logout.svg');
export const TrashCanIcon = require('./trash-can.svg');
export const TiktokIconWhite = require('./tiktok-white.svg');
export const TwitterIcon = require('./twitterX.svg');
export const StarIcon = require('./star.svg');
export const StarWhiteIcon = require('./star-white.svg');
export const TwitterColorIcon = require('./twitter-color.svg');
export const TwitterWhiteIcon = require('./twitter-white.svg');
export const TwitterCircleIcon = require('./twitter-circle.svg');
export const TwitterXWhiteIcon = require('./twitterX-white.svg');
export const TwitterXDisabledIcon = require('./twitterX-disabled.svg')
export const TwitterCircleColorIcon = require('./twitterX-circle-color.svg');
export const TwitterXCommunityIcon = require('./twitterX-community.svg');
export const TwitterCircleBorderIcon = require('./twitter-circle-border.svg');
export const TwitterDisabledIcon = require('./twitter-disabled.svg');
export const UserIcon = require('./user.svg');
export const UserColorIcon = require('./user-color.svg');
export const UserFollowIcon = require('./user-follow.svg');
export const UserFollowColorIcon = require('./user-follow-color.svg');
export const ViewIcon = require('./view.svg');
export const ViewIconScheduler = require('./view-scheduler.svg');
export const WarningIcon = require('./warning.svg');
export const WarningRedIcon = require('./warning-red.svg');
export const WarningYellowIcon = require('./warning-yellow.svg');
export const CheckIcon = require('./check.svg');
export const CheckBrownIcon = require('./brown-check.svg');
export const FailIcon = require('./fail.svg');
export const EditorScratchIcon = require('./editor-scratch.svg');
export const EditorUploadIcon = require('./editor-upload.svg');
export const ProfilePlaceholder = require('./profile-placeholder.svg');
export const ProfilePlaceholderColored = require('./profile-placeholder-colored.svg');
export const CommentInstagramIcon = require('./comment-instagram.svg');
export const CommentTwitterIcon = require('./comment-twitter.svg');
export const DirectInstagramIcon = require('./direct-instagram.svg');
export const RetweetIcon = require('./retweet.svg');
export const SaveInstagramIcon = require('./save-instagram.svg');
export const ShareFacebookIcon = require('./share-facebook-icon.svg');
export const QuestionIcon = require('./question.svg');
export const QuestionCircleIcon = require('./circle-question.svg');
export const QuestionCircleGreenIcon = require('./circle-question-green.svg');
export const TooltipSignIcon = require('./tooltip-exc.svg');
export const TooltipSignBigIcon = require('./tooltip-exc-big.svg');
export const PreviewFacebookColor = require('./preview-facebook-color.svg');
export const PreviewInstagramColor = require('./preview-instagram-color.svg');
export const PreviewTwitterColor = require('./preview-twitter-color.svg');
export const PreviewFacebookDisabled = require('./preview-facebook-disabled.svg');
export const PreviewInstagramDisabled = require('./preview-instagram-disabled.svg');
export const PreviewTwitterDisable = require('./preview-twitter-disabled.svg');
export const NoViewEye = require('./no-view-eye.svg');
export const ViewEye = require('./view-eye.svg');
export const CrossMark = require('./crossMark.svg');
export const HelpIcon = require('./help.svg');
export const ApprovedIcon = require('./approved.svg');
export const ApprovedIconMobile = require('./approved-mobile.svg');
export const NextArrowIcon = require('./next-arrow-circle.svg');
export const BeforeArrowIcon = require('./before-arrow-circle.svg');
export const BeforeArrowIconBlack = require('./before-arrow-circle-black.svg');
export const YellowCheck = require('./yellow-check.svg');
export const CheckGreen = require('./check-green.svg');
export const InstagramDashboardIcon = require('./instagram-dashboard.svg');
export const FacebookDashboardIcon = require('./facebook-dashboard.svg');
export const TwitterDashboardIcon = require('./twitter-dashboard.svg');
export const LinkedinDashboardIcon = require('./linkedin-dashboard.svg');
export const PreviewSocialIconstIG = require('./preview-social-icons-instagram.svg');
export const shareTwitter = require('./share-twitter.svg');
export const favoriteTwitter = require('./favorite-twitter.svg');
export const previewLinkedinActive = require('./preview-linkedin-active.svg');
export const previewLinkedinDisabled = require('./preview-linkedin-disabled.svg');
export const multiImageIcon = require('./multi-image.svg');
export const emailReminder = require('./email-reminder.svg');
export const emailColor = require('./email-color.svg');
export const UploadLogoEditorEN = require('./my-logo-en.svg');
export const UploadLogoEditorES = require('./my-logo-es.svg');

export const stripeIcon = require('./cards/card_stripe.png');
export const stripeText = require('./cards/stripe-text.svg');
export const visaIcon = require('./cards/card_v.svg');
export const masterCardIcon = require('./cards/card_mc.svg');
export const americanExpressIcon = require('./cards/card_ae.svg');
export const discoverIcon = require('./cards/card_d.svg');

export const PreviewLike = path.join(__dirname, '/assets/locale/en/preview-like.svg');
export const PreviewComment = path.join(__dirname, '/assets/locale/en/preview-comment.svg');
export const PreviewShare = path.join(__dirname, '/assets/locale/en/preview-share.svg');

export const CopyIcon = require('./copy.svg');

export const SigInAppleIconEN = require('./loginApple-black.en.svg');
export const SigInAppleIconES = require('./loginApple-black.es.svg');

export const SigUnAppleIconEN = require('./signupApple-black.en.svg');
export const SigUnAppleIconES = require('./signupApple-black.en.svg');

export const AppleIconButton = require('./apple-logo.svg');

export const NotificationIconLogo = require('./piper-notificacion-ico.svg');
export const NotificationLogo = require('./piper-notificacion.svg');
export const PiperBlockBussiness = require('./piper-settings-business.svg');
export const PiperBlockAudience = require('./piper-settings-audience.svg');
export const PiperBlockSocial = require('./piper-setting-social.svg');
export const PiperBlockSchedule = require('./piper-schedule.svg');

export const StorieIGPostflow = require('./storie-ig-postflow.svg');
export const StorieIGPostflowSelect = require('./storie-ig-postflow-select.svg');
export const StorieIGCalendar = require('./storie-ig-calendar.svg');
export const Thunderbold = require('./thunderbolt.svg');
export const TiktokOriginalIcon = require('./tiktok-original.svg');
export const TiktokColorIcon = require('./tiktok-logo-icon.svg');
export const TiktokWhiteIcon = require('./tiktok-white-icon.svg');
export const TrustIcon = require('./trust-icon.svg');
export const previewStoriesActive = require('./preview-stories-active.svg');
export const previewStoriesDisabled = require('./preview-stories-disabled.svg');
export const previewTiktokActive = require('./preview-tiktok-active.svg');
export const previewTiktokDisabled = require('./preview-tiktok-disabled.svg');
export const tiktokSideIcons = require('./tiktok-side-icons.svg');
export const storiesTopIcons = require('./stories-top-icons.svg');
export const storiesBottomIcons = require('./stories-bottom-icons.svg');
export const playButtonPreview = require('./play-button-preview.svg');

export const tiktokTopIcons = {
  en: require('./tiktok-top-icons-en.svg'),
  es: require('./tiktok-top-icons-es.svg'),
};
