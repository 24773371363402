/* eslint-disable import/no-dynamic-require */
const landingDir = './landing';
const otherSources = './otherSources';
const community = './community';
const tooltips = './onboardingTooltips';
const postflow = './postflow';
const pricing = './pricing';
const landingInstagram = './landingInstagram';
const liAddPaymentModal = './liAddPaymentModal';
const fbCreateAdAccount = './fbCreateAdAccount';
const tools = './tools';

export const HeaderImage = {
  desktop: require(`${landingDir}/header-desktop.jpg`),
  mobile: require(`${landingDir}/header-mobile.jpg`),
};

export const santanderBadge = {
  desktop: require(`${landingDir}/badge-santander-desktop.svg`),
  desktopEs: require(`${landingDir}/badge-santander-desktop-es.svg`),
};

export const Promotional1 = require(`${landingDir}/SocialPiper-socialmeadia-easy-header.jpg`);
export const PromotionalOct2020 = {
  desktop: require(`${landingDir}/SocialPiper-Header.jpg`),
  tablet: require(`${landingDir}/SocialPiper-Header-tablet.jpg`),
  mobile: require(`${landingDir}/SocialPiper-Header-mobile.jpg`),
};
export const ToolsImg = {
  tool1: {
    desktop: require(`${landingDir}/tool1.png`),
  },
  tool2: {
    desktop: require(`${landingDir}/tool2.png`),
  },
  tool3: {
    desktop: require(`${landingDir}/tool3X.png`),
  },
  tool4: {
    desktop: require(`${landingDir}/tool4-desktop.png`),
    desktopEs: require(`${landingDir}/tool4-desktop-es.png`),
    mobile: require(`${landingDir}/tool4-mobile.png`),
    mobileEs: require(`${landingDir}/tool4-mobile-es.png`),
  },
};
export const CreatePostImage = require(`${landingDir}/SocialPiper-create.jpg`);
export const CreatePostImageEsp = require(`${landingDir}/socialpiper-create-esp.jpg`);

export const AcademyImage = {
  desktop: require(`${landingDir}/academy-landing-foto-desktop.jpg`),
  tablet: require(`${landingDir}/academy-landing-foto-tablet-portrait.jpg`),
  mobile: require(`${landingDir}/academy-landing-foto-mobile.jpg`),
};
export const AppBannerImage = {
  desktop: require(`${landingDir}/app-banner-desktop.jpg`),
  tablet: require(`${landingDir}/app-banner-tablet.jpg`),
  mobile: require(`${landingDir}/app-banner-mobile.jpg`),
};

export const AppDevicesImage = {
  desktop: require(`${landingDir}/app-devices.png`),
  desktopEsp: require(`${landingDir}/app-devices-esp.png`),
  mobile: require(`${landingDir}/app-devices-mobile.png`),
  mobileEsp: require(`${landingDir}/app-devices-mobile-esp.png`),
};

export const testimonialAvatar1 = require(`${landingDir}/testimonials/avatar-heather.png`);
export const testimonialAvatar2 = require(`${landingDir}/testimonials/avatar-regina.png`);
export const testimonialAvatar3 = require(`${landingDir}/testimonials/avatar-marco.png`);
export const testimonialAvatar4 = require(`${landingDir}/testimonials/avatar-denisse.png`);
export const testimonialAvatar5 = require(`${landingDir}/testimonials/avatar-liza.png`);

export const testimonialAvatars = {
  avatar1: testimonialAvatar1,
  avatar2: testimonialAvatar2,
  avatar3: testimonialAvatar3,
  avatar4: testimonialAvatar4,
  avatar5: testimonialAvatar5,
};

export const PiperStepsImg = {
  meetPiper: {
    desktop: require(`${landingDir}/meet-piper-desktop-en.svg`),
    tabletPro: require(`${landingDir}/meet-piper-tabletPro-en.svg`),
    tablet: require(`${landingDir}/meet-piper-tablet-en.svg`),
    mobile: require(`${landingDir}/meet-piper-mobile-en.svg`),
  },
  schedule: {
    desktop: require(`${landingDir}/socialPiper-schedule-desktop-en.png`),
    tabletPro: require(`${landingDir}/socialPiper-schedule-tabletPro-en.png`),
    tablet: require(`${landingDir}/socialPiper-schedule-tablet-en.png`),
    mobile: require(`${landingDir}/socialPiper-schedule-mobile-en.png`),
  },
  post: {
    desktop: require(`${landingDir}/socialPiper-post-desktop-en.png`),
    tabletPro: require(`${landingDir}/socialPiper-post-tabletPro-en.png`),
    tablet: require(`${landingDir}/socialPiper-post-tablet-en.png`),
    mobile: require(`${landingDir}/socialPiper-post-mobile-en.png`),
  },
  budget: {
    desktop: require(`${landingDir}/socialPiper-budget-desktop-en.png`),
    tabletPro: require(`${landingDir}/socialPiper-budget-tabletPro-en.png`),
    tablet: require(`${landingDir}/socialPiper-budget-tablet-en.png`),
    mobile: require(`${landingDir}/socialPiper-budget-mobile-en.png`),
  },
};

export const PiperStepsImgEsp = {
  meetPiper: {
    desktop: require(`${landingDir}/meet-piper-desktop-es.svg`),
    tabletPro: require(`${landingDir}/meet-piper-tabletPro-es.svg`),
    tablet: require(`${landingDir}/meet-piper-tablet-es.svg`),
    mobile: require(`${landingDir}/meet-piper-mobile-es.svg`),
  },
  schedule: {
    desktop: require(`${landingDir}/socialPiper-schedule-desktop-es.png`),
    tabletPro: require(`${landingDir}/socialPiper-schedule-tabletPro-es.png`),
    tablet: require(`${landingDir}/socialPiper-schedule-tablet-es.png`),
    mobile: require(`${landingDir}/socialPiper-schedule-mobile-es.png`),
  },
  post: {
    desktop: require(`${landingDir}/socialPiper-post-desktop-es.png`),
    tabletPro: require(`${landingDir}/socialPiper-post-tabletPro-es.png`),
    tablet: require(`${landingDir}/socialPiper-post-tablet-es.png`),
    mobile: require(`${landingDir}/socialPiper-post-mobile-es.png`),
  },
  budget: {
    desktop: require(`${landingDir}/socialPiper-budget-desktop-es.png`),
    tabletPro: require(`${landingDir}/socialPiper-budget-tabletPro-es.png`),
    tablet: require(`${landingDir}/socialPiper-budget-tablet-es.png`),
    mobile: require(`${landingDir}/socialPiper-budget-mobile-es.png`),
  },
};

export const BoostingPowerImg = {
  desktop: require(`${landingDir}/boostingpower.svg`),
  mobile: require(`${landingDir}/SocialPiper-boostingpower-mobile.png`),
};

export const BoostingPowerImgEsp = {
  desktop: require(`${landingDir}/Socialpiper-boostingpower-esp.png`),
  mobile: require(`${landingDir}/Socialpiper-boostingpower-mobile-esp.png`),
};

export const PoweredByGiphy = require(`${otherSources}/PoweredBy_200px-White_HorizText.png`);

const dashboardDir = './dashboard';
export const budgetNoDataImage = require(`${dashboardDir}/budget-no-data.jpg`);
export const budgetNoDataImageMD = require(`${dashboardDir}/budget-no-data-MD.jpg`);
export const commentsNoDataImage = require(`${dashboardDir}/comments-no-data.jpg`);
export const commentsNoDataImageMD = require(`${dashboardDir}/comments-no-data-MD.jpg`);
export const competitorsNoDataImage = require(`${dashboardDir}/competitors-no-data.jpg`);
export const metricsNoDataImage = require(`${dashboardDir}/metrics-no-data.jpg`);
export const metricsNoDataImageSM = require(`${dashboardDir}/metrics-no-data-SM.jpg`);
export const mostActivePostNoDataImage = require(`${dashboardDir}/most-active-no-data.jpg`);
export const scheduleNoDataImage = require(`${dashboardDir}/schedule-no-data.jpg`);
export const templatesNoDataImage = require(`${dashboardDir}/templates-no-data.jpg`);
export const CompetitorsPostPlaceholder = require('./competitorsPostPlaceholder.png');
export const socialMissingPermissionsES = require('./social-error-missing-permissions-es.png');
export const socialMissingPermissionsEN = require('./social-error-missing-permissions-en.png');

export const budgetNoDataImageEs = require(`${dashboardDir}/budget-no-data-es.jpg`);
export const commentsNoDataImageEs = require(`${dashboardDir}/comments-no-data-es.jpg`);
export const competitorsNoDataImageEs = require(`${dashboardDir}/competitors-no-data-es.jpg`);
export const metricsNoDataImageEs = require(`${dashboardDir}/metrics-no-data-es.jpg`);
export const mostActivePostNoDataImageEs = require(`${dashboardDir}/most-active-no-data-es.jpg`);
export const scheduleNoDataImageEs = require(`${dashboardDir}/schedule-no-data.jpg`);
export const templatesNoDataImageEs = require(`${dashboardDir}/templates-no-data.jpg`);
export const CompetitorsPostPlaceholderEs = require('./competitorsPostPlaceholder.png');

export const suggestionDesktop = require(`${dashboardDir}/suggestion-desktop.png`);
export const suggestionTabletLandscape = require(`${dashboardDir}/suggestion-tabletlandscape.png`);
export const suggestionTabletPortrait = require(`${dashboardDir}/suggestion-tabletportrait.png`);
export const suggestionCCDesktop = require(`${dashboardDir}/suggestion-CreativeCenter-desktop.png`);
export const suggestionCCTablet = require(`${dashboardDir}/suggestion-CreativeCenter-tablet.png`);

export const DesktopCommunity1 = require(`${community}/Desktop-community-1.jpg`);
export const DesktopCommunity2 = require(`${community}/Desktop-community-2.jpg`);
export const DesktopCommunity3 = require(`${community}/Desktop-community-3.jpg`);
export const DesktopInbox1 = require(`${community}/Desktop-inbox-1.jpg`);
export const DesktopInbox2 = require(`${community}/Desktop-inbox-2.jpg`);
export const DesktopInbox3 = require(`${community}/Desktop-inbox-3.jpg`);
export const MobileComments = require(`${community}/Mobile-comments.jpg`);
export const MobileInbox = require(`${community}/Mobile-inbox.jpg`);
export const TabletCommunity1 = require(`${community}/Tablet-community-1.jpg`);
export const TabletCommunity2 = require(`${community}/Tablet-community-2.jpg`);
export const TabletCommunity3 = require(`${community}/Tablet-community-3.jpg`);
export const TabletInbox1 = require(`${community}/Tablet-inbox-1.jpg`);
export const TabletInbox2 = require(`${community}/Tablet-inbox-2.jpg`);
export const TabletInbox3 = require(`${community}/Tablet-inbox-3.jpg`);
export const TwitterIcons = require(`${postflow}/twitter-icons.png`);
export const IgIcons = require(`${postflow}/ig-icons.png`);
export const backgroundTemplates = require(`${postflow}/background-postflow.png`);
export const blankHolder = require(`${postflow}/blankHolder.svg`);
export const tiktokPublishAnimateEN = require(`${postflow}/tiktok-gif-post-en.gif`);
export const tiktokPublishAnimateES = require(`${postflow}/tiktok-gif-post-es.gif`);

export const onboardingTooltips = {
  Age: {
    en: require(`${tooltips}/Age-en.jpg`),
    es: require(`${tooltips}/Age-es.jpg`),
  },
  AudienceGraphic: {
    en: require(`${tooltips}/AudienceGraphic-en.jpg`),
    es: require(`${tooltips}/AudienceGraphic-es.jpg`),
  },
  AudienceInterest: {
    en: require(`${tooltips}/AudienceInterest-en.jpg`),
    es: require(`${tooltips}/AudienceInterest-es.jpg`),
  },
  AudienceLocation: {
    en: require(`${tooltips}/AudienceLocation-en.jpg`),
    es: require(`${tooltips}/AudienceLocation-es.jpg`),
  },
  BusinessCategory: {
    en: require(`${tooltips}/BusinessCategory-en.jpg`),
    es: require(`${tooltips}/BusinessCategory-es.jpg`),
  },
  BusinessName: {
    en: require(`${tooltips}/BusinessName-en.jpg`),
    es: require(`${tooltips}/BusinessName-es.jpg`),
  },
  Gender: {
    en: require(`${tooltips}/Gender-en.jpg`),
    es: require(`${tooltips}/Gender-es.jpg`),
  },
  YourCity: {
    en: require(`${tooltips}/YourCity-en.jpg`),
    es: require(`${tooltips}/YourCity-es.jpg`),
  },
};

export const ThumbnailVideoImage = {
  desktop: require(`${pricing}/video-thumbnail-desktop-en.png`),
  tabletPro: require(`${pricing}/video-thumbnail-tabletPro-en.png`),
  tablet: require(`${pricing}/video-thumbnail-tablet-en.png`),
  mobile: require(`${pricing}/video-thumbnail-mobile-en.png`),
};
export const ThumbnailVideoImageEs = {
  desktop: require(`${pricing}/video-thumbnail-desktop-es.png`),
  tabletPro: require(`${pricing}/video-thumbnail-tabletPro-es.png`),
  tablet: require(`${pricing}/video-thumbnail-tablet-es.png`),
  mobile: require(`${pricing}/video-thumbnail-mobile-es.png`),
};

export const ActionsImageEs = {
  publishStories: {
    desktop: require(`${landingInstagram}/publish-schedule-stories-desktop-es.png`),
    tabletPro: require(`${landingInstagram}/publish-schedule-stories-tabletPro-es.png`),
    tablet: require(`${landingInstagram}/publish-schedule-stories-tablet-es.png`),
    mobile: require(`${landingInstagram}/publish-schedule-stories-mobile-es.png`),
  },
  publishSimultaneous: {
    desktop: require(`${landingInstagram}/publish-simultaneous-desktop-es.png`),
    tabletPro: require(`${landingInstagram}/publish-simultaneous-tabletPro-es.png`),
    tablet: require(`${landingInstagram}/publish-simultaneous-tablet-es.png`),
    mobile: require(`${landingInstagram}/publish-simultaneous-mobile-es.png`),
  },
  recommendations: {
    desktop: require(`${landingInstagram}/get-recommendations-desktop-es.png`),
    tabletPro: require(`${landingInstagram}/get-recommendations-tabletPro-es.png`),
    tablet: require(`${landingInstagram}/get-recommendations-tablet-es.png`),
    mobile: require(`${landingInstagram}/get-recommendations-mobile-es.png`),
  },
  publishVideosImages: {
    desktop: require(`${landingInstagram}/publish-videos-images-desktop-es.png`),
    tabletPro: require(`${landingInstagram}/publish-videos-images-tabletPro-es.png`),
    tablet: require(`${landingInstagram}/publish-videos-images-tablet-es.png`),
    mobile: require(`${landingInstagram}/publish-videos-images-mobile-es.png`),
  },
  addLogo: {
    desktop: require(`${landingInstagram}/add-logo-desktop-es.png`),
    tabletPro: require(`${landingInstagram}/add-logo-tabletPro-es.png`),
    tablet: require(`${landingInstagram}/add-logo-tablet-es.png`),
    mobile: require(`${landingInstagram}/add-logo-mobile-es.png`),
  },
};

export const ActionsImageWebpEs = {
  publishStories: {
    desktop: require(`${landingInstagram}/publish-schedule-stories-desktop-es.webp`),
    tabletPro: require(`${landingInstagram}/publish-schedule-stories-tabletPro-es.webp`),
    tablet: require(`${landingInstagram}/publish-schedule-stories-tablet-es.webp`),
    mobile: require(`${landingInstagram}/publish-schedule-stories-mobile-es.webp`),
  },
  publishSimultaneous: {
    desktop: require(`${landingInstagram}/publish-simultaneous-desktop-es.webp`),
    tabletPro: require(`${landingInstagram}/publish-simultaneous-tabletPro-es.webp`),
    tablet: require(`${landingInstagram}/publish-simultaneous-tablet-es.webp`),
    mobile: require(`${landingInstagram}/publish-simultaneous-mobile-es.webp`),
  },
  recommendations: {
    desktop: require(`${landingInstagram}/get-recommendations-desktop-es.webp`),
    tabletPro: require(`${landingInstagram}/get-recommendations-tabletPro-es.webp`),
    tablet: require(`${landingInstagram}/get-recommendations-tablet-es.webp`),
    mobile: require(`${landingInstagram}/get-recommendations-mobile-es.webp`),
  },
  publishVideosImages: {
    desktop: require(`${landingInstagram}/publish-videos-images-desktop-es.webp`),
    tabletPro: require(`${landingInstagram}/publish-videos-images-tabletPro-es.webp`),
    tablet: require(`${landingInstagram}/publish-videos-images-tablet-es.webp`),
    mobile: require(`${landingInstagram}/publish-videos-images-mobile-es.webp`),
  },
  addLogo: {
    desktop: require(`${landingInstagram}/add-logo-desktop-es.webp`),
    tabletPro: require(`${landingInstagram}/add-logo-tabletPro-es.webp`),
    tablet: require(`${landingInstagram}/add-logo-tablet-es.webp`),
    mobile: require(`${landingInstagram}/add-logo-mobile-es.webp`),
  },
};

export const ActionsImage = {
  publishStories: {
    desktop: require(`${landingInstagram}/publish-schedule-stories-desktop-en.png`),
    tabletPro: require(`${landingInstagram}/publish-schedule-stories-tabletPro-en.png`),
    tablet: require(`${landingInstagram}/publish-schedule-stories-tablet-en.png`),
    mobile: require(`${landingInstagram}/publish-schedule-stories-mobile-en.png`),
  },
  publishSimultaneous: {
    desktop: require(`${landingInstagram}/publish-simultaneous-desktop-en.png`),
    tabletPro: require(`${landingInstagram}/publish-simultaneous-tabletPro-en.png`),
    tablet: require(`${landingInstagram}/publish-simultaneous-tablet-en.png`),
    mobile: require(`${landingInstagram}/publish-simultaneous-mobile-en.png`),
  },
  recommendations: {
    desktop: require(`${landingInstagram}/get-recommendations-desktop-en.png`),
    tabletPro: require(`${landingInstagram}/get-recommendations-tabletPro-en.png`),
    tablet: require(`${landingInstagram}/get-recommendations-tablet-en.png`),
    mobile: require(`${landingInstagram}/get-recommendations-mobile-en.png`),
  },
  publishVideosImages: {
    desktop: require(`${landingInstagram}/publish-videos-images-desktop-en.png`),
    tabletPro: require(`${landingInstagram}/publish-videos-images-tabletPro-en.png`),
    tablet: require(`${landingInstagram}/publish-videos-images-tablet-en.png`),
    mobile: require(`${landingInstagram}/publish-videos-images-mobile-en.png`),
  },
  addLogo: {
    desktop: require(`${landingInstagram}/add-logo-desktop-en.png`),
    tabletPro: require(`${landingInstagram}/add-logo-tabletPro-en.png`),
    tablet: require(`${landingInstagram}/add-logo-tablet-en.png`),
    mobile: require(`${landingInstagram}/add-logo-mobile-en.png`),
  },
};

export const ActionsImageWebp = {
  publishStories: {
    desktop: require(`${landingInstagram}/publish-schedule-stories-desktop-en.webp`),
    tabletPro: require(`${landingInstagram}/publish-schedule-stories-tabletPro-en.webp`),
    tablet: require(`${landingInstagram}/publish-schedule-stories-tablet-en.webp`),
    mobile: require(`${landingInstagram}/publish-schedule-stories-mobile-en.webp`),
  },
  publishSimultaneous: {
    desktop: require(`${landingInstagram}/publish-simultaneous-desktop-en.webp`),
    tabletPro: require(`${landingInstagram}/publish-simultaneous-tabletPro-en.webp`),
    tablet: require(`${landingInstagram}/publish-simultaneous-tablet-en.webp`),
    mobile: require(`${landingInstagram}/publish-simultaneous-mobile-en.webp`),
  },
  recommendations: {
    desktop: require(`${landingInstagram}/get-recommendations-desktop-en.webp`),
    tabletPro: require(`${landingInstagram}/get-recommendations-tabletPro-en.webp`),
    tablet: require(`${landingInstagram}/get-recommendations-tablet-en.webp`),
    mobile: require(`${landingInstagram}/get-recommendations-mobile-en.webp`),
  },
  publishVideosImages: {
    desktop: require(`${landingInstagram}/publish-videos-images-desktop-en.webp`),
    tabletPro: require(`${landingInstagram}/publish-videos-images-tabletPro-en.webp`),
    tablet: require(`${landingInstagram}/publish-videos-images-tablet-en.webp`),
    mobile: require(`${landingInstagram}/publish-videos-images-mobile-en.webp`),
  },
  addLogo: {
    desktop: require(`${landingInstagram}/add-logo-desktop-en.webp`),
    tabletPro: require(`${landingInstagram}/add-logo-tabletPro-en.webp`),
    tablet: require(`${landingInstagram}/add-logo-tablet-en.webp`),
    mobile: require(`${landingInstagram}/add-logo-mobile-en.webp`),
  },
};

export const relaxInstagramImage = {
  desktop: require(`${landingInstagram}/instagram-relax-desktop.png`),
  tabletPro: require(`${landingInstagram}/instagram-relax-tabletPro.png`),
  tablet: require(`${landingInstagram}/instagram-relax-tablet.png`),
  mobile: require(`${landingInstagram}/instagram-relax-mobile.png`),
};

export const relaxInstagramImageWebp = {
  desktop: require(`${landingInstagram}/instagram-relax-desktop.webp`),
  tabletPro: require(`${landingInstagram}/instagram-relax-tabletPro.webp`),
  tablet: require(`${landingInstagram}/instagram-relax-tablet.webp`),
  mobile: require(`${landingInstagram}/instagram-relax-mobile.webp`),
};

export const liAddPaymentMethodImage = {
  step2: require(`${liAddPaymentModal}/linkedin-payment-2-en.png`),
  step3: require(`${liAddPaymentModal}/linkedin-payment-3-en.png`),
  step3Mobile: require(`${liAddPaymentModal}/linkedin-payment-3-mobile-en.png`),
  step4: require(`${liAddPaymentModal}/linkedin-payment-4-en.png`),
};

export const liAddPaymentMethodImageEs = {
  step2: require(`${liAddPaymentModal}/linkedin-payment-2-es.png`),
  step3: require(`${liAddPaymentModal}/linkedin-payment-3-es.png`),
  step3Mobile: require(`${liAddPaymentModal}/linkedin-payment-3-mobile-es.png`),
  step4: require(`${liAddPaymentModal}/linkedin-payment-4-es.png`),
};

export const fbCreateAdAccountImage = {
  step1: require(`${fbCreateAdAccount}/fb-create-adaccount-1-en.png`),
  step2: require(`${fbCreateAdAccount}/fb-create-adaccount-2-en.png`),
  step3: require(`${fbCreateAdAccount}/fb-create-adaccount-3-en.png`),
  step4: require(`${fbCreateAdAccount}/fb-create-adaccount-4-en.png`),
  step5: require(`${fbCreateAdAccount}/fb-create-adaccount-5-en.png`),
  step6: require(`${fbCreateAdAccount}/fb-create-adaccount-6-en.png`),
  step7: require(`${fbCreateAdAccount}/fb-create-adaccount-7-en.png`),
  step8: require(`${fbCreateAdAccount}/fb-create-adaccount-8-en.png`),
  step9: require(`${fbCreateAdAccount}/fb-create-adaccount-9.svg`),
};

export const fbCreateAdAccountImageEs = {
  step1: require(`${fbCreateAdAccount}/fb-create-adaccount-1-es.png`),
  step2: require(`${fbCreateAdAccount}/fb-create-adaccount-2-es.png`),
  step3: require(`${fbCreateAdAccount}/fb-create-adaccount-3-es.png`),
  step4: require(`${fbCreateAdAccount}/fb-create-adaccount-4-es.png`),
  step5: require(`${fbCreateAdAccount}/fb-create-adaccount-5-es.png`),
  step6: require(`${fbCreateAdAccount}/fb-create-adaccount-6-es.png`),
  step7: require(`${fbCreateAdAccount}/fb-create-adaccount-7-es.png`),
  step8: require(`${fbCreateAdAccount}/fb-create-adaccount-8-es.png`),
  step9: require(`${fbCreateAdAccount}/fb-create-adaccount-9.svg`),
};

export const MoreActionsImage = {
  templates: {
    image: require(`${landingInstagram}/templates.png`),
  },
  publishVideos: {
    image: require(`${landingInstagram}/publish-videos.png`),
  },
  recommendations: {
    image: require(`${landingInstagram}/recommendations.png`),
  },
  messages: {
    image: require(`${landingInstagram}/messages.png`),
  },
};

export const MoreActionsImageWebp = {
  templates: {
    image: require(`${landingInstagram}/templates.webp`),
  },
  publishVideos: {
    image: require(`${landingInstagram}/publish-videos.webp`),
  },
  recommendations: {
    image: require(`${landingInstagram}/recommendations.webp`),
  },
  messages: {
    image: require(`${landingInstagram}/messages.webp`),
  },
};

export const HeaderTools = {
  mobile: require(`${tools}/header-mobile.jpg`),
  tablet: require(`${tools}/header-tablet.jpg`),
  desktop: require(`${tools}/header-desktop.jpg`),
};

export const ToolsImages = {
  toolCreate: {
    desktop: require(`${tools}/create-en.png`),
    desktopEs: require(`${tools}/create-es.png`),
  },
  toolPost: {
    desktop: require(`${tools}/post-en.png`),
    desktopEs: require(`${tools}/post-es.png`),
  },
  toolBlastoff: {
    desktop: require(`${tools}/blastoff-en.png`),
    desktopEs: require(`${tools}/blastoff-es.png`),
  },
  toolConnect: {
    desktop: require(`${tools}/connect-en.png`),
    desktopEs: require(`${tools}/connect-es.png`),
  },
};

export const SliderToolsImages = {
  instagram: {
    desktop: require(`${tools}/instagram-en.png`),
    desktopEs: require(`${tools}/instagram-es.png`),
  },
  time: {
    desktop: require(`${tools}/time-x-en.png`),
    desktopEs: require(`${tools}/time-x-es.png`),
  },
  bestTime: {
    desktop: require(`${tools}/best-time-en.png`),
    desktopEs: require(`${tools}/best-time-es.png`),
  },
  boost: {
    desktop: require(`${tools}/boost-en.png`),
    desktopEs: require(`${tools}/boost-es.png`),
  },
};
