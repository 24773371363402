export const TESTING_ENVIRONMENT = 'testing';
export const LOCAL_ENVIRONMENT = 'local';
export const LOGO_MAX_SIZE_MB = 2;
export const LOGO_MAX_DIMENSIONS = {
  height: 1000,
  width: 1000,
};
export const LANGUAGES_SUFIX = {
  en: '',
  es: 'Es',
};
export const ALL = 'all';
export const INSTAGRAM = 'instagram';
export const FACEBOOK = 'facebook';
export const TWITTER = 'twitter';
export const X = 'x';
export const LINKEDIN = 'linkedin';
export const STORIES_IG = 'storiesIG';
export const TIKTOK = 'tiktok';
export const APPLE = 'apple';
export const AUTH0 = 'auth0';
export const SOCIAL_MEDIA = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  twitter: 'X',
  linkedin: 'LinkedIn',
  tiktok: 'TikTok',
  storiesIG: 'Stories IG',
};
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DSN;
export const ENV = process.env.REACT_APP_ENV;
export const ENVIRONMENTS = {
  local: 'local',
  development: 'develop',
  staging: 'staging',
  production: 'production',
};
export const UNSPLASH_KEY = process.env.REACT_APP_UNSPLASH_KEY;
export const UNSPLASH_API_URL = 'https://api.unsplash.com/photos';
export const UNSPLASH_API_SEARCH_URL = 'https://api.unsplash.com/search/photos';
export const UNSPLASH_API_DEFAULT_PAGE_LIMIT = 100;
export const GLOBAL_DEBOUNCE_DELAY = 700;
export const GIPHY_KEY = process.env.REACT_APP_GIPHY_KEY;
export const GIPHY_API_SEARCH_URL = 'https://api.giphy.com/v1/gifs/search';
export const GIPHY_API_URL = 'https://api.giphy.com/v1/gifs/trending';
export const ACTIVE = 'active';
export const USD = 'USD';
export const UTM_SOURCE = 'socialpiper';
export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID;
export const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION;
export const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
export const GTM_KEY = process.env.REACT_APP_GTM_KEY;
export const INTERCOM_ID = process.env.REACT_APP_INTERCOM_ID;
export const PLAN_CODE_ASEM = 'ASEM';
export const PLAN_CODE_CICC = 'CICC';
export const PLAN_CODE_APREN = 'APREN';
export const PLAN_CODE_SP100 = 'SP100';
export const PLAN_CODE_AMAI = 'AMAI';
export const PLAN_CODE_COVID_RELIEF = 'COVID_RELIEF';
export const GOOGLE_SITE_KEY = process.env.REACT_APP_GOOGLE_SITE_KEY;
export const COUNTRY_MX = 'MX';
export const COUNTRY_US = 'US';
export const PLAN_LITE = 'basic';
export const PLAN_PRO = 'pro';
export const PLAN_BUSINESS = 'business';
export const FILES_PERMITED = ['image/jpeg', 'image/png', 'image/svg+xml', 'video/mp4', 'video/quicktime'];
export const VIDEO_PERMITED_TYPE = ['video/mp4', 'video/quicktime'];
export const INSTAGRAM_MAX_DURATION = 60;
export const TWITTER_MAX_DURATION = 135;
export const INSTAGRAM_MAX_SIZE = 104857600;
export const LINKED_IN_MIN_AUDIENCE = 2100; // 300 per day for 7 days
export const MULTISELECTOR = 'MULTISELECTOR';
export const STATUS_VIEW = {
  INITIAL: 'initial',
  DEFAULT_VALUES: 'mock',
  ACTIVE: 'active',
  REJECTED: 'rejected',
};
export const HELP_HERO_ID = process.env.REACT_APP_HELP_HERO_ID;
export const HELP_HERO_TOUR_ID = process.env.REACT_APP_HELP_HERO_TOUR_ID;

export const FB_LOGIN_CONFIG_ID = process.env.REACT_APP_FACEBOOK_LOGIN_CONFIG || "";
