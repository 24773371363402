import { TRIAL_DURATION } from '../../../../utils/constants/settings';

const en = {
  'onboarding.creteAccount.acceptAllTerms': 'By registering, you are accepting: <terms>Terms of Service</terms>, <cookies>Cookies Policy</cookies>, <privacy>Privacy Policy</privacy>, <caPrivacy>CA Privacy Rights</caPrivacy> and <doNotSell>Do Not Sell My Information</doNotSell> of Social Piper, and you will be automatically registered to receive emails about new Social Piper products, best practices or special offers, until you opt out.',
  'onboarding.AboutAudience.age': 'Age',
  'onboarding.creteAccount.heading': 'Create your account',
  'onboarding.createAccount.selectPlan.save': 'Save 2 months',
  'onboarding.createAccount.selectPlan.title': 'Select your Plan',
  'onboarding.AboutAudience.gender': 'Gender',
  'onboarding.AboutAudience.GenderAll': 'ALL',
  'onboarding.AboutAudience.GenderFemale': 'FEMALE',
  'onboarding.AboutAudience.GenderMale': 'MALE',
  'onboarding.createAccount.BoostingPowerTitle': 'Get Boosting Power!',
  'onboarding.createAccount.cookies': 'I accept the Social Piper <a>Cookies Policy</a>',
  'onboarding.createAccount.description': 'Start your 7-day free trial.',
  'onboarding.createAccount.policy': 'I have read the <a>Privacy Policy</a>',
  'onboarding.createAccount.title': 'Create your account',
  'onboarding.createAccount.TOS': 'I accept the Social Piper <a>Terms of Service</a>',
  'onboarding.createAccount.wantsEmails': 'I want to receive emails about new Social Piper products, best practices or special offers',
  'onboarding.creteAccount.14DayTrial': '14 day free trial*',
  'onboarding.creteAccount.30DayTrial': '30 day free trial*',
  'onboarding.creteAccount.7DayTrial': '7 day free trial*',
  'onboarding.creteAccount.BoostingPower': '<strong>Piper Insights</strong> help you Boost to your biggest audience and take control of your spending with AI-driven messages',
  'onboarding.creteAccount.BoostingPowerParagraph': 'For example: "<strong>With $150</strong> your post can reach up to <strong>17,000 users.</strong>"',
  'onboarding.creteAccount.email': 'E-mail',
  'onboarding.creteAccount.firstname': 'First name',
  'onboarding.creteAccount.lastname': 'Last name',
  'onboarding.creteAccount.limitedTime': 'Limited time',
  'onboarding.creteAccount.limitedTimeOffer': 'For a <green>limited time</green>, activate your subscription in Social Piper, receive a <strong>30% off in our monthly fee</strong> ($99/month normal rate) and we’re also going to <strong>deposit back the first month value in your credit card</strong> so you can use it to create online campaign to reach more clients (or whatever other use your business need).',
  'onboarding.creteAccount.loginWithFB': 'Log in with Facebook',
  'onboarding.creteAccount.optout': 'I dont want to recive emails about new Social Piper products best practices, or special offers.',
  'onboarding.creteAccount.or': 'or',
  'onboarding.creteAccount.password': 'Password',
  'onboarding.creteAccount.mobile': 'Mobile (Optional)',
  'onboarding.creteAccount.pricinginfo': 'No risk. No obligation.',
  'onboarding.creteAccount.pricinginfo2': 'You will be charged <strong>$99/month</strong> if you continue after the free trial.',
  'onboarding.creteAccount.pricinginfodisclaimer': '*Social Piper subscription fees will NOT be charged during the free trial. Advertising fees / commissions may apply.',
  'onboarding.creteAccount.reactivateBusinessSubTitle': 'to reactivate your business!',
  'onboarding.creteAccount.reactivateBusinessTitle': 'We give you money <subtitle>to reactivate your business!</subtitle>',
  'onboarding.creteAccount.signUpWithFB': 'Sign Up with Facebook',
  'onboarding.creteAccount.signUpWithFB.free': 'and start my free trial',
  'onboarding.creteAccount.signUp': 'Sign Up!',
  'onboarding.creteAccount.subheader': 'Use your business contact information to start your free 7 day trial.',
  'onboarding.creteAccount.noRisk': 'No risk. No obligation.',
  'onboarding.creteAccount.TOS': 'By continuing, I accept the Social Piper <tos>Terms of Service</tos> and have read the <privacypolicy>Privacy Policy.</privacypolicy>',
  'onboarding.creteAccount.trialmessage': '* 7 day free trial. $99/month after trial. No credit card needed during free trial.',
  'onboarding.creteAccount.trialmessageMobile': '<strong>Get your 7 day Free Trial!</strong> Try it out and start growing your business on social. ',
  'onboarding.creteAccount.trialPricing': '$99/month',
  'onboarding.creteAccount.trial.title': `Start your ${TRIAL_DURATION}-day free trial*`,
  'onboarding.redirectModal.title': 'Connect your accounts to create your first post',
  'onboarding.redirectModal.tip': 'Now, choose what you want to do next',
  'onboarding.redirectModal.createPost.text': 'Create your first post!',
  'onboarding.redirectModal.createPost.button': 'Create',
  'onboarding.redirectModal.mediaLibrary.text': 'Browse our free content library',
  'onboarding.redirectModal.mediaLibrary.subtext': 'and select something to post',
  'onboarding.redirectModal.mediaLibrary.button': 'Explore',
  'onboarding.redirectModal.social.text': 'Connect your social accounts',
  'onboarding.redirectModal.social.subtext': 'and add competitors',
  'onboarding.redirectModal.social.button': 'Connect',
  'onboarding.redirectModal.dashboard': 'Or go to the Dashboard to start exploring the rest of Social Piper',
  'onboarding.piperPresentation.piperTip': 'Hi, I’m Piper, your expert social media guide. I’ll be at your side with insights and advice, every step of the way should you need it.',
  'onboarding.stepper.first': 'CREATE ACCOUNT',
  'onboarding.stepper.second': 'YOUR BUSINESS INFO',
  'onboarding.stepper.third': 'Your Audience',
  'onboarding.stepper.fourth': 'YOUR PLAN',
  'onboarding.creteAccount.doyouhavecode': 'Do you have a promo code?',
  'onboarding.creteAccount.validcode': 'Valid Code',
  'onboarding.creteAccount.validcode2': 'Valid Code. The trial days included in the coupon are only valid for new accounts.',
  'onboarding.creteAccount.invalidcode': 'Invalid Code',
  'onboarding.pricing.titleCICC': 'Special Offer for CICC Members:',
  'onboarding.pricing.titleASEM': 'Special Offer for ASEM Members:',
  'onboarding.pricing.titleAMAI': 'Special Offer for AMAI Members:',
  'onboarding.pricing.titleAPREN': 'Special Offer for Aprendamos Marketing Members:',
  'onboarding.pricing.titleSP100': 'Special account:',
  'onboarding.pricing.OfferCICC': '<strong>Monthly Subscription:</strong> {lineBreak}<no>$99</no> <red>USD $29/Month</red> <small><up>1</up></small>{lineBreak}<strong>Yearly Subscription:</strong> {lineBreak}<no>$990</no> <red>USD $290</red><small><up>2</up></small>',
  'onboarding.pricing.OfferASEM': '<strong>Monthly Subscription:</strong> {lineBreak}<no>$99</no> <red>USD $29/Month</red> <small><up>1</up></small>{lineBreak}<strong>Yearly Subscription:</strong> {lineBreak}<no>$990</no> <red>USD $290</red><small><up>2</up></small>',
  'onboarding.pricing.OfferMonthly': '<strong>Monthly Subscription:</strong> {lineBreak}',
  'onboarding.pricing.OfferYearly': '<strong>Yearly Subscription:</strong> {lineBreak}',
  'onboarding.pricing.descriptionOfferCICC': '{lineBreak}This offer is open for all CICC members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}',
  'onboarding.pricing.descriptionOfferASEM': '{lineBreak}This offer is open for all ASEM members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}',
  'onboarding.pricing.descriptionOfferAMAI': '{lineBreak}This offer is open for all AMAI members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}',
  'onboarding.pricing.descriptionOfferAPREN': '{lineBreak}This offer is open for all Aprendamos Marketing members to start using and enjoying Social Piper. To get the benefits, create your account and activate your subscription before the offer expires.{lineBreak}',
  'onboarding.pricing.descriptionOfferSP100': 'This account is for special purposes; All functionalities are available at no monthly cost but it does not exclude the boost fees.{lineBreak}',
  'onboarding.pricing.termsOfferCICC': 'Promotion valid until March 2021, Limited to 2,000 redeems. Promotion will be applied once the user activates their account.',
  'onboarding.pricing.termsOfferASEM': 'Promotion valid until March 2021, Limited to 20,000 redeems. Promotion will be applied once the user activates their account.',
  'onboarding.pricing.termsOfferAMAI': 'Promotion valid until March 2021, Limited to 10,000 redeems. Promotion will be applied once the user activates their account.',
  'onboarding.pricing.termsOfferAPREN': 'Promotion limited to 1000 redemptions. This promotion will be applied once the user activates his account.',
  'onboarding.createAccount.title.app': 'Create account with the Pro plan',
  'signup.createAccount.title': 'Create your account',
  'signup.createAccount.option.text': 'Or sign up with:',
  'onboarding.creteAccount.title': 'You are creating an account in the  <r>PRO plan, {days}-day free trial.</r>',
  'onboarding.creteAccount.title.notrial': 'You are creating an account in the  <r>PRO plan.</r>',
  'onboarding.creteAccount.title.full':'You are creating an account in the <r>Full managment plan.</r>',
  'onboarding.creteAccount.subtitle.optout': 'You will not be charged during your trial period.',
  'onboarding.creteAccount.subtitle.trial.noCode': 'No credit card required for trial. <lText>Compare plans.</lText>',
  'onboarding.creteAccount.subtitle.optin': 'No risk. No obligation.',
  'onboarding.creteAccount.email.holder': 'You will receive an account verification link for this email.',
  'onboarding.createAccount.text.required': '* Required',
  'onboarding.launcher.dashboard': 'Skip and go to Dashboard',
  'onboarding.launcher.connect.facebook': 'Connect your Facebook business page',
  'onboarding.launcher.connect.instagram': 'Connect your Instagram business account. <Highlight>Show me how</Highlight>',
  'onboarding.launcher.connect.instagram.noFacebook': 'To connect Instagram, first connect Facebook. <Highlight>Why?</Highlight>',
  'onboarding.launcher.connect.twitter': 'Connect your X acount',
  'onboarding.launcher.connect.linkedin': 'Connect your Linkedin account or page',
  'onboarding.launcher.connect.tiktok': 'Connect your Tiktok account or page',
  'onboarding.launcher.connect.facebook.helpInfo': 'Don’t you have a Facebook Business page? <LinkInfo>Create one now</LinkInfo>',
  'onboarding.launcher.connect.instagram.helpInfo': 'Don’t know if you have a business account? <LinkInfo>Review these steps</LinkInfo>',
  'onboarding.launcher.modal.title1': 'First, authenticate with Facebook',
  'onboarding.launcher.modal.item1': '<TextHighLight>Meta</TextHighLight> requires that your Instagram account has been previously switched to a Business account and it\'s also linked to a business Facebook page.',
  'onboarding.launcher.modal.boxHelp.item': 'If you haven\'t switch it, just {lineBreak}<LinkSteps>follow these steps,</LinkSteps> it\'s very easy.',
  'onboarding.launcher.modal.subItem1': 'If you haven’t linked them yet <LinkSteps>follow these steps.</LinkSteps>',
  'onboarding.launcher.modal.title2': 'Next...',
  'onboarding.launcher.modal.item2': 'You will sign in to the Facebook profile that has Admin permission for the Facebook Page connected with your Instagram business profile.',
  'onboarding.launcher.modal.subItem2': 'If you don’t know if your Instagram profile is a business profile <LinkGuide>follow this guide</LinkGuide> or <LinkSteps>make it business by following these steps.</LinkSteps>',
  'onboarding.launcher.modal.button': 'Connect Instagram via {lineBreak}Facebook',
  'signup.createAccount.app.helpText1': '<TextBold>You are creating an account in the</TextBold> <r>PRO plan {days} day-free trial.</r> <LinkText>See plan features here.</LinkText> You will not be charged during your trial period.',
  'signup.createAccount.haveAccount.text': 'I have an account',
  'signup.createAccount.text.required': '* Required',
  'onboarding.launcher.meeting.card.title': '“Don\'t want to connect your accounts yet?”',
  'onboarding.launcher.meeting.card.body.text': '<h>Schedule a 1-on1- consultation</h> to guide you and show {linebreak}you the full possibilities of Social Piper for your business',
  'onboarding.launcher.meeting.card.button': 'Schedule',
  'onBoarding.launcer.trustMark.description': 'Secure {lineBreak}information',
  'onBoarding.launcher.trustMark.text': 'We are an app approved by:',
};

export default en;
